@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

@font-face {
    font-family: Syne-Bold;
    src: url('../fonts/Syne-Bold.ttf');
}

@font-face {
    font-family: Syne-ExtraBold;
    src: url('../fonts/Syne-ExtraBold.ttf');
}

@font-face {
    font-family: Syne-Medium;
    src: url('../fonts/Syne-Medium.ttf');
}

@font-face {
    font-family: Syne-Regular;
    src: url('../fonts/Syne-Regular.ttf');
}

@font-face {
    font-family: Syne-SemiBold;
    src: url('../fonts/Syne-SemiBold.ttf');
}

@font-face {
    font-family: Futura_Bold_font;
    src: url('../fonts/Futura_Heavy_font.ttf');
}

@font-face {
    font-family: futura_medium_bt;
    src: url('../fonts/futura_medium_bt.ttf');
}

@font-face {
    font-family: Futura_Book_font;
    src: url('../fonts/Futura_Book_font.ttf');
}

:root {
    --text-color: #fff;
    --text-color2: #1a171e;
    --text-color2: #1a171e;
    --text-color3: #444449;
    --text-color1: #00207b;
    --bg-color1: #2458f3;
    --bg-color: #4aa5f9;
    --bg-colo2: #1e1c17;
    --bg-color3: #00207b;
    --bg-color4: #F7F6FB;
    --border-color: #2458f3;
    --border-color1: #b4b1b9;
    --backg-color: #0d9ad2;
    --Syne-Bold: "Open Sans, Sans-serif";
    --Syne-ExtraBold: Open Sans,
        Sans-serif;
    --gilory-semiBold: Open Sans,
        Sans-serif;
    --Syne-Medium: Open Sans,
        Sans-serif;
    --Syne-Regular: Open Sans,
        Sans-serif;
    --Syne-SemiBold: Open Sans,
        Sans-serif;

}

h1,
h2,
h3,
h4,
h5,
h6,
p,
li,
a {
    letter-spacing: 1px;
}

body {
    position: relative;
    width: 100%;
    height: auto;
    font-family: var(--Syne-Regular);

}

.mb-40px {
    margin-bottom: 50px;
}

/* width */
::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
    background: #1a171e;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: var(--bg-color3);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: #555;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1111;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 20px;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

header.sticky {
    background-color: transparent;
    padding-top: 10px;
    padding-bottom: 18px;


}

.header-right-section .logo-section {
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
    opacity: 0;
    /* display: none; */
}

.header-right-section {
    display: flex;
    align-items: center;
    position: relative;
    padding: 0;
    overflow: hidden;
    width: 300px;
    height: 100%;
    /* background: var(--bg-color3); */
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}

.header-right-section::after {
    position: absolute;
    content: '';
    right: -100%;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--bg-colo2);
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
    padding: 20px;
}

header.sticky .header-right-section::after {
    /* animation: width1 1s ease-in-out alternate; */
    right: 0%;
}

.header-right-section .logo-section {
    z-index: 2;
    border-right: 1px solid #878787cf;
    justify-content: center;
    align-items: center;
}

@keyframes width1 {
    0% {
        right: -100%;
    }

    100% {
        right: 0%;
    }
}

.header-right-section .logo-section a {
    display: flex;
    align-items: center;
    justify-content: center;
}

header .logo-section a img {
    width: 70%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

header.sticky .logo-section a img {
    width: 65%;
}

.header-right-section .logo-section {
    padding: 12px 15px;
}

header.sticky .header-right-section .logo-section {
    opacity: 1;
    transform: translateY(0);
    display: flex;
    /* width: 170px; */
}

header.sticky .header-right-section .logo-section a img {
    width: 70%;
}

.left_logo img {
    -webkit-transition: all 0.8s ease 0s;
    -moz-transition: all 0.8s ease 0s;
    -ms-transition: all 0.8s ease 0s;
    -o-transition: all 0.8s ease 0s;
    transition: all 0.8s ease 0s;
}

header.sticky .left_logo img {
    opacity: 0;
}

.menu {
    z-index: 2;
    cursor: pointer;
    height: 60px;
    width: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--bg-colo2);
    padding: 10px 15px;

}

.mobile-menu {
    position: fixed;
    top: 0;
    left: -100%;
    background-color: var(--bg-color3);
    width: 80%;
    height: 100%;
    z-index: 2;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.close-button {
    position: absolute;
    right: 20px;
    top: 20px;
    color: #fff;
    background-color: transparent;
    width: 40px;
    height: 40px;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 35px;
    z-index: 3;
}

.close-button:hover {
    animation: rotate 0.8s ease-in-out alternate;
}

@keyframes rotate {

    100%,
    0% {
        transform: rotate(0deg);
    }

    50% {
        transform: rotate(45deg);
    }
}

.mobile-menu.active {
    width: 100%;
    left: 0%;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    /* animation: moveing 1s ease-in-out alternate; */
}

@-webkit-keyframes moveing {

    100%,
    0% {
        left: 0%;
        width: 100%;
    }

    20% {
        left: 5%;
        width: 90%;
    }

    40% {
        left: -5%;
        width: 85%;
    }

    70% {
        left: 5%;
        width: 95%;
    }
}

@keyframes moveing {

    100%,
    0% {
        left: 0%;
        width: 100%;
    }

    20% {
        left: 5%;
        width: 90%;
    }

    40% {
        left: -5%;
        width: 85%;
    }

    70% {
        left: 5%;
        width: 95%;
    }
}

.mobile-menu-list {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    justify-content: center;
    padding-left: 80px;
    margin-right: 180px;
    padding-top: 150px;
    z-index: 2;
}

.mobile-menu-list .sub-menu {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    position: relative;

}

.mobile-menu-list .sub-menu p {
    color: var(--Syne-Regular);
    padding: 5px 0;
    cursor: pointer;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    color: #fff;
}

.mobile-menu-list .sub-menu p i {
    font-size: 14px;
    margin-bottom: -2px;
    margin-left: 5px;
}


.mobile-menu-list .sub-menu ul {
    padding-left: 0;
    margin-bottom: 0;

    justify-content: center;
    align-items: flex-end;
    flex-direction: column;
    transform: translateX(-500px);
    opacity: 0;
    display: flex;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.mobile-menu-list .sub-menu.active ul {
    transform: translateX(0);
    opacity: 1;
    display: flex;
}

.mobile-menu-list .sub-menu li {
    padding-left: 0;
    margin-bottom: 0;
    text-align: right;

}

.mobile-menu-list li a {
    text-decoration: none;
    padding: 0 0;
    color: #fff;
    font-family: var(--Syne-Regular);
    font-size: 26px;
    display: flex;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    text-transform: uppercase;
}

.mobile-menu-list li a:hover {
    margin-right: 10px;
}

.mobile-menu .mobile-menu-list {
    /* transform: translateX(-300%);
    opacity: 0;
    transition-delay: 0.7s; */
}

.mobile-menu .mobile-menu-list li {
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: end;
    width: fit-content;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    position: relative;

}

.mobile-menu .mobile-menu-list li::after {
    position: absolute;
    content: '';
    right: 0;
    top: 0;
    width: 0%;
    height: 100%;
    z-index: -1;
}


@keyframes width {

    100%,
    0% {
        width: 110%;
    }

    20% {
        width: 30%;
    }

    50% {
        width: 80%;
    }
}

/* @keyframes scrolling-right {
    100% {
        transform: translateX(0);
    }

    0% {
        transform: translatex(-144vw);
    }
} */
.mobile-menu .mobile-menu-list li:hover::after {
    animation: width 0.8s linear alternate;
    background-color: var(--bg-colo2);
    width: 100%;
}


.mobile-menu .mobile-menu-list li a:nth-last-child(2) {
    transform: translateX(-300%);
    opacity: 0;
    transition-delay: 0.8s;
}

.mobile-menu .mobile-menu-list li a:nth-last-child(3) {
    transform: translateX(-300%);
    opacity: 0;
    transition-delay: 0.9s;
}

.mobile-menu .mobile-menu-list li a:nth-last-child(4) {
    transform: translateX(-300%);
    opacity: 0;
    transition-delay: 1s;
}

.mobile-menu .mobile-menu-list li a:nth-last-child(5) {
    transform: translateX(-300%);
    opacity: 0;
    transition-delay: 1.10s;
}

.mobile-menu .mobile-menu-list li.sub-menu {
    transform: translateX(-300%);
    opacity: 0;
    transition-delay: 1.10s;
}

.mobile-menu.active .mobile-menu-list li a:nth-last-child(1) {
    transform: translateX(0);
    opacity: 1;
}

.mobile-menu.active .mobile-menu-list li a:nth-last-child(2) {
    transform: translateX(0);
    opacity: 1;
}

.mobile-menu.active .mobile-menu-list li a:nth-last-child(3) {
    transform: translateX(0);
    opacity: 1;
}

.mobile-menu.active .mobile-menu-list li a:nth-last-child(4) {
    transform: translateX(0);
    opacity: 1;

}

/* .mobile-menu.active .mobile-menu-list {
    transform: translateX(0);
    opacity: 1;
} */

/* .mobile-menu.active .mobile-menu-list .sub-menu {
    transform: translateX(0);
    opacity: 1;
} */

.mobile-menu .row {
    height: 100%;
}

.mobile-menu .row .colum-menu {
    height: 100%;
}

.menu-right-img {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 10px 0px 0 0;
}

.menu-right-img .lamp_light_section {
    width: 28%;
    position: absolute;
    height: 100%;
    margin-right: 32px;
    position: absolute;
    right: 0;
}

.menu-right-img .lamp_light_section img {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
    width: 100%;
}

.light-img::after {
    position: absolute;
    content: '11111';
    left: 0;
    top: 0;
    border-top: 10px solid #fff;
    z-index: 111111111;
    width: 100px;
    height: 400px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.lamp_light {
    max-width: 412px;
    width: 40%;
    z-index: 1;
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
    padding-top: 84px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
}

.lamp-b {
    width: 200px;
    height: 0px;
    border-bottom: 340px solid rgb(100 100 100 / 34%);
    border-left: 88px solid transparent;
    border-right: 85px solid transparent;
    margin: 0px auto;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

header .menu p {
    color: var(--text-color2);
    font-family: var(--Syne-Bold);
    font-size: 18px;
    margin-bottom: 0;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

header.sticky .menu p {
    color: #fff;
}

header .menu-bar {
    margin-left: 0;
}

header .menu-bar span {
    height: 2.8px;
    width: 35px;
    background-color: #fff;
    position: relative;
    display: block;
    display: flex;
    align-items: center;
    flex-direction: column;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;

}

header.sticky .menu-bar span {
    background-color: #fff;
}

header .menu-bar.active span:nth-child(1) {
    transform: rotate(45deg) translateX(2px);
}

header .menu-bar.active span:nth-child(2) {
    transform: rotate(-45deg) translateX(4px) translateY(-2px);
}

header .menu-bar span:nth-child(1) {
    margin-bottom: 4px;
}

.padding-left-right {
    padding-left: 60px;
    padding-right: 60px;
}

.top {
    padding-top: 60px;

}

.bottom {
    padding-bottom: 120px;
}

.bottom_100px {
    padding-bottom: 60px;
}

.heading {
    font-family: var(--Syne-Bold);
    font-size: 50px;
    line-height: 65px;
}

.sub-heading {
    font-size: 18px;
    line-height: 25px;
    font-family: var(--Syne-Bold);
    /* font-weight: 700; */
}

.paragraph {
    line-height: 35px;
    font-size: 22px;
}

.paragraph1 {
    line-height: 25px;
    font-size: 16px;
    font-family: var(--Syne-Regular);
}

.btn-class {
    padding: 8px 50px;
    font-family: var(--Syne-Bold);
    font-size: 16px;
    border-radius: 50px;
}

.banner-section {
    width: 100%;
    background-color: var(--bg-color);
    position: relative;
}

.banner-section::after {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.4);
    content: '';
}

.banner-section img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.banner-section .banner-content {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 60%;
    z-index: 2;
}

.banner-section .dot-img {
    position: absolute;
    left: 58px;
    top: 131px;
    width: 80px;
    opacity: 0.4;
}

.banner-section .banner-content h1 {

    margin: 8px 0 35px;
    font-weight: 600;
    color: var(--text-color);
    font-family: var(--Syne-Bold);
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    transition-delay: 0.4s;
    transform: translateY(-200px);
    opacity: 0;
}

.banner-section .banner-content h6 {

    color: rgb(250, 199, 16);
    font-family: var(--Syne-Bold);
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    transform: translateX(200px);

    opacity: 0;
    transition-delay: 0.6s;
    position: relative;
}



.banner-section .banner-content p {
    color: var(--text-color);

    margin-bottom: 0;
    font-family: var(--Syne-Regular);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    transition-delay: 0.8s;
    width: 80%;
    transform: translateY(200px);
    margin-bottom: 50px;
    font-size: 18px;
    line-height: 24px;

}

.banner-section .banner-content a {
    display: block;
    background-color: rgb(245, 190, 10);
    color: var(--text-color);
    position: relative;
    width: fit-content;
    font-size: 20px;
    text-decoration: none;
    font-family: var(--roboto);
    font-weight: 500;
    -webkit-transition: all 0.4s ease 0s;
    -moz-transition: all 0.4s ease 0s;
    -ms-transition: all 0.4s ease 0s;
    -o-transition: all 0.4s ease 0s;
    transition: all 0.4s ease 0s;
    transform: translateY(200px);
    opacity: 0;
    transition-delay: 1s;
    position: relative;

}

.banner-section .banner-content a span {
    transform: scale(0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--text-color2);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.4s;
    -moz-transition: all 0.4s;
    -ms-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    transition-delay: 0.2s;
}

.banner-section .banner-content a:hover span {
    transform: scale(1);
    opacity: 1;
}

.banner-section .banner-content a:hover {
    border: 2px solid var(--bg-bottom);
}

.banner-section .banner-content a::before {
    content: "";
    background: var(--text-color);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.banner-section .banner-content a:hover {
    color: #fff;
}

.banner-section .banner-content a:hover::before {
    width: 100%;
    color: var(--text-color2);
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.swiper-slide.swiper-slide-active .banner-content h1 {
    transform: translateY(0);
    opacity: 1;
}

.swiper-slide.swiper-slide-active .banner-content h6 {
    transform: translateX(0);
    opacity: 1;
}

.swiper-slide.swiper-slide-active .banner-section .banner-content p {
    transform: translateY(0);
    opacity: 1;
}

.swiper-slide.swiper-slide-active .banner-content a {
    transform: translateY(0);
    opacity: 1;
}

.banner-section .shap {
    position: absolute;
    left: 35px;
    top: 180px;
    z-index: 1;
}

.banner-section .shap img {
    width: 70%;
}

.marketing-section {
    padding-top: 120px;
}

.marketing-section .left-section .sub-heading {
    color: var(--text-color1) !important;
    margin-bottom: 15px;
}

.marketing-section .left-section p {
    color: var(--text-color3);
    margin: 15px 0 30px;
}

.video-play-btn {
    border: none;
    outline: none;
    background-color: transparent;
    display: flex;
    align-items: center;
    margin-top: 34px;
}

.video-play-btn i {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    border: 2px solid var(--border-color);
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--text-color1);
}

.video-play-btn span {
    font-family: var(--Syne-Bold);
    color: var(--text-color1);
    font-size: 18px;
    margin-left: 20px;
    display: block;
}

.logo-slider {
    margin-top: 40px;
    padding-right: 50px;
}

.logo-slider .swiper-slide img {
    width: 100%;
    object-fit: contain;
    height: 70px;
}

.right_s {
    position: relative;
}

.right-section {
    width: 100%;
    padding: 0 0 0 120px;
    position: relative;
}

.right-section img {
    width: 100%;
    border-radius: 20px;
}

.countup-section {
    position: absolute;
    bottom: 10%;
    left: 20px;
    width: 300px;
    padding: 15px 20px;
    border-radius: 20px;
    z-index: 1;
    box-shadow: 0 0 6px 2px rgb(184 169 169 / 50%);
    background-color: #fff;
    display: flex;
    align-items: center;
    width: auto;
}

.countup-section .count_box {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    width: 110px;
}

.countup-section .count_box h5 {
    font-size: 22px;
    font-family: var(--Syne-Bold);
    margin-bottom: 0;
    display: flex;
    align-items: center;

}

.countup-section .count_box p {
    font-size: 12px;
    color: var(--text-color3);
    font-family: var(--Syne-Bold);
    margin-bottom: 0;
}

.service-child-section {
    position: relative;
    padding: 60px 70px;
    background-color: #444449;
}

.service-child-section .heading {
    color: var(--text-color);
    z-index: 2;
    position: relative;
}

.service-child-section .sub-heading {
    color: var(--text-color);
    z-index: 2;
    position: relative;
}

.service-child-section .bg-banner {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0.1;
    /* display: none; */

}

.marketing-slider {
    z-index: 11;
    margin-top: 70px;
    padding-bottom: 80px !important;
}

.marketing-slider .swiper-slide .card-box {
    position: relative;
}

.marketing-slider .swiper-slide .card-box a {
    text-decoration: none;
    color: #fff;
}

.marketing-slider .swiper-slide .card-box img {
    position: relative;
    width: 100%;
    border-radius: 10px;
}

.marketing-slider .swiper-slide .card-box h4 {
    color: var(--text-color);
    font-family: var(--Syne-Bold);
    margin: 18px 0 18px;
    font-size: 20px;
}

.marketing-slider .swiper-slide .card-box p {
    color: var(--text-color);
    font-family: var(--Syne-Regular);
    margin-bottom: 0;
    font-size: 16px;
}

.marketing-slider .swiper-pagination-bullet {
    width: 15px !important;
    height: 15px !important;
    opacity: 1 !important;
    background-color: #fff !important;
    transition: 0.5s;
}

.marketing-slider .swiper-pagination-bullet-active {
    width: 65px !important;
    border-radius: 50px !important;
    background-color: transparent !important;
    border: 2px solid #fff !important;
}

.explain-more {
    position: relative;
    z-index: 1;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 40px;
}

.explain-more a {
    display: block;
    background-color: #fff;
    color: var(--text-color2);
    position: relative;
    width: fit-content;
    font-size: 20px;
    text-decoration: none;
    font-family: var(--Syne-Bold);
    font-weight: 500;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;


}

.explain-more a span {
    transform: scale(0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--text-color);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    transition-delay: 0.2s;
}

.explain-more a:hover span {
    transform: scale(1);
    opacity: 1;
}

.explain-more a::before {
    content: "";
    background: var(--text-color2);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.explain-more a:hover {
    color: #fff;
}

.explain-more a:hover::before {
    width: 100%;
    color: var(--text-color2);
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.featured-section .sub-heading {
    color: var(--text-color1);
}

.featured-section .header-title {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.featured-section .header-title a {
    text-decoration: none;
    color: var(--text-color1);
    font-size: 18px;
    font-family: var(--Syne-Bold);
    display: flex;
    align-items: center;
}

.featured-section .header-title a i {
    font-size: 20px;
    margin-left: 15px;
}

/* Gallery section */

.gallery {
    display: flex;
    flex-wrap: wrap;
}

.gallery-card {
    margin: 15px;
}

.gallery-card img {
    height: 250px;
    width: 320px;
    border-radius: 5px;
}

.gallery-image img {
    height: 250px;
    width: 250px;
    margin: 15px;
}

.testimonialSwiper .swiper-wrapper {
    padding-bottom: 45px;
}

.testimonialSwiper .swiper-pagination-bullet {
    width: 10px !important;
    height: 10px !important;
}

.testimonialSwiper .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background-color: var(--bg-colo2) !important;
}

.slider_container .slider_3 {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.slider_container .slider_3 h6 {
    font-family: var(--Syne-Medium);
    font-size: 16px;
    color: var(--bg-colo2);
    padding: 40px 0 5px;
    margin-bottom: 0;
}

.slider_container .slider_3 .department {
    color: var(--bg-color3);
    font-size: 14px;
    font-family: var(--Syne-Medium);
}

/* .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  } */
.slider-icons-img img {
    margin: 0;
    height: 130px;
    width: 130px;
    border-radius: 50%;
    margin-bottom: 10px;
}

.slider-text {
    font-family: var(--Syne-Bold);
    text-align: center;
}

.slider-text h4 {
    color: rgb(255, 213, 0);
    margin-top: 20px;
}

.slider-text h2 {
    /* font-size: 50px; */
}

.slider-para {
    margin: 40px 0 30px;
    text-align: center;
    font-family: var(--Syne-Medium);
    font-weight: 500;
    color: var(--text-color3);
    width: 55%;
    font-size: 20px;
}

.blue-line {
    width: 55px;
    height: 2px;
    background-color: var(--bg-color3);
    text-align: center;
}

.slider-btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.marquee {
    /*   overflow: hidden; */
    overflow: hidden;
}

.marquee-content {
    display: flex;
    /* animation: scrolling 30s linear infinite; */

}

.marquee-item {
    flex: 0 0 16vw;
    margin: 0 1vw;
    float: right;
    /*   flex: 0 0 20vw; */
    /*   margin: 0 2vw; */
}

.marquee.right {
    margin-top: 30px;
}

.marquee.left .marquee-content {
    animation: scrolling-left 25s linear infinite;
}

.marquee.right .marquee-content {
    animation: scrolling-right 25s linear infinite;
}

.marquee .marquee-content:hover {
    animation-play-state: paused;
}

.marquee-item img {
    display: block;
    width: 100%;
    /*   padding: 0 20px; */
}

@keyframes scrolling-left {
    0% {
        transform: translateX(0);
    }

    100% {
        transform: translatex(-144vw);
    }
}

@keyframes scrolling-right {
    100% {
        transform: translateX(0);
    }

    0% {
        transform: translatex(-144vw);
    }
}

/* portfolio_section */
.portfolio_section {
    position: relative;
    margin-bottom: 20px;
}


.portfolio-slider {
    padding-left: 50px;
    padding-right: 50px;
    position: relative;
}

.portfolio_section .swiper-button-next,
.portfolio_section .swiper-button-prev {
    width: 70px !important;
    height: 70px !important;
    border-radius: 50%;
    background-color: #fff;
    box-shadow: 0 0 5px 5px rgb(225 215 215 / 60%);
    border: none !important;
    position: relative;
    top: auto !important;
    font-size: 35px;
}

.cuttom-slider-button-box {
    position: absolute;
    left: 0;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 100%;
    width: 100%;
}

.portfolioSwiper .swiper-slide .card {
    border-radius: 10px !important;
    /* border: none !important; */
    overflow: hidden;
    border: 1px solid #f7f6fb !important;
}

.portfolioSwiper .swiper-slide .card-body {
    background-color: #f7f6fb;
}

.portfolioSwiper .swiper-slide .card-body p {
    color: var(--text-color2);
    font-family: var(--Syne-Bold);
    font-size: 16px;
}

.portfolioSwiper .swiper-slide .card img {
    width: 100%;
    height: 250px;
    object-fit: cover;
}


.portfolio_section .swiper-button-next {
    left: 0 !important;
    right: 0 !important;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.portfolio_section .swiper-button-prev {
    right: 0 !important;
    left: 0 !important;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.portfolio_section .swiper-button-next:hover,
.portfolio_section .swiper-button-prev:hover {
    background-color: var(--bg-color3);
    color: #fff;
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
    opacity: 1 !important;
}

.portfolio_section .swiper-button-prev:after,
.portfolio_section .swiper-button-next:after {
    color: var(--text-color1);
    font-size: 28px;
    font-weight: 700;
    display: none !important;
}

.portfolio_section .swiper-button-prev:hover::after,
.portfolio_section .swiper-button-next:hover:after {
    color: #fff !important;

}

/* Business  */

.business {
    margin-bottom: 20px;
    position: relative;
}

.fontact-us-home {
    margin-bottom: 20px;
}

.fontact-us-home .sub-heading {
    color: var(--bg-color3) !important;
    text-transform: uppercase;
}

.business-body {
    /* background-image: url("../images/bg-why.png"); */
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 70px 60px 100px;
    background-size: cover;
    position: relative;

}

.business-body::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
}

.business-body h6 {
    color: #fff;
    font-family: var(--Syne-Medium);
    font-size: 20px;
    z-index: 2;
    position: relative;
}

.business-body h2 {
    color: #fff;
    z-index: 2;
    position: relative;
}

.business-body .paragraph1 {
    color: #fff;
    width: 50%;
    font-size: 14px;
    z-index: 2;
    position: relative;
}

.box-text {
    text-align: center;
    margin: 10px;
    z-index: 2;
}

.box-text p {
    font-weight: normal;
}

.business-body .card-box {
    padding-top: 100px;
    position: relative;
    z-index: 2;
}

.business-body .card-box .cards {
    background: var(--bg-color3);
    border-radius: 2px;
    display: inline-block;
    height: auto;
    position: relative;
    width: auto;
    color: #fff;
    width: 100%;
    padding: 30px 20px 45px;
    border-radius: 5px;
    transition: 0.5s;

}

.business-body .card-box .cards h4 {
    font-family: var(--Syne-Bold);
    font-size: 22px;
}

.business-body .card-box .cards p {
    font-size: 16px;
    margin-bottom: 0;
    padding-top: 15px;
}

.business-body .card-box .cards {
    border: none;
    letter-spacing: 10px;
    position: relative;
    overflow: hidden;
}

.business-body .card-box .cards:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 4px;
    background-color: #fff;
    transition: all 1s;
}

.business-body .card-box .cards:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 4px;
    background-color: #fff;
    transition: all 1s;
}

.business-body .card-box .cards:hover:before {
    left: 0;
}

.business-body .card-box .cards:hover:after {
    right: 0;
}

.line_border:before {
    content: '';
    position: absolute;
    top: -100%;
    right: 0%;
    width: 4px;
    height: 100%;
    background-color: #fff;
    transition: all 1s;
}

.business-body .card-box .cards:hover .line_border:before {
    top: 0;
}

.line_border:after {
    content: '';
    position: absolute;
    bottom: -100%;
    left: 0%;
    width: 4px;
    height: 100%;
    background-color: #fff;
    transition: all 1s;
}

.business-body .card-box .cards:hover .line_border:after {
    bottom: 0;
}

ul {
    list-style: none;
}

input {
    overflow: hidden;
}

.section-title {
    position: relative;
    font-size: 30px;
    font-weight: 600;
    font-family: var(--Syne-Medium);
    margin: 0 0 35px;
}

.sec-pad {
    padding: 60px 0 0;
    margin: 50px 0;
}

.contact-sec {
    align-items: center;
    display: flex;
    /* background-color: #5cbde466; */
    padding-left: 50px;
    padding-right: 50px;
}

.contact-sec .contact-ul li,
.contact-ul b {
    font-size: 20px;
    margin: 10px 0;
    font-family: Cambria, Cochin, Georgia, Times, "Times New Roman", serif;
    word-wrap: break-word;
}

.contact-sec .contact-ul i {
    font-size: 18px;
    padding: 10px;
    margin-right: 10px;
    border-radius: 50%;
}

.contact-detail .call-to-action {
    display: flex;
    flex-direction: column;
    padding: 30px 0 20px;
    width: 55%;
}

.contact-detail .call-to-action a {
    display: flex;
    flex-direction: column;
    background-color: var(--bg-color3);
    text-decoration: none;
    color: #fff;
    width: fit-content;
    padding: 10px 30px;
    margin-bottom: 30px;
    border-radius: 50px;
    font-family: var(--Syne-Medium);
    font-size: 16px;
    transition: 0.5s;
    width: 100%;
    display: flex;
    align-items: center;
    flex-direction: row;
}

.contact-detail .call-to-action a i {
    margin-right: 5px;
}

.contact-detail .call-to-action a:hover {
    background-color: var(--bg-colo2);
    color: #fff;
}

.contact-detail .social_media {
    position: relative;
}

.contact-detail .social_media p {
    position: relative;
    font-family: var(--Syne-Regular);
    font-size: 16px;
    color: var(--text-color2);
}

.contact-detail .social_media ul {
    display: flex;
    align-items: center;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}

.contact-detail .social_media ul li a {
    color: #fff;
    background-color: var(--bg-colo2);
    padding: 5px;
    width: 35px;
    height: 35px;
    border-radius: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 15px;
    text-decoration: none;
    transition: 0.5s;

}

.contact-detail .social_media ul li a:hover {
    background-color: var(--bg-color3);
    color: #fff;
}


.contact-form {
    width: 100%;
}

.contact-form-footer {
    width: 100%;
    padding: 50px;
    /* background-color: #f7f6fb; */
    border-radius: 10px;
    background-color: #b4b1b9;

}

.colum-menu {
    padding-left: 100px;
}

.colum-menu .contact-detail {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    color: #fff;
}

.colum-menu .contact-detail .social_media p {
    color: #fff;
}

.colum-menu .contact-detail .call-to-action a {
    background-color: transparent;
    color: #fff;
    border: 1px solid #fff;
}

.colum-menu .contact-detail .social_media a:hover {
    background-color: var(--bg-color);
}

.footer-fomr-section {
    padding-left: 40px;
}

.colum-menu .contact-detail .call-to-action a:hover {
    background-color: var(--bg-color);
}

.contact-form-footer form {
    width: 100%;
}

.contact-form label {
    font-family: var(--Syne-Medium);
    /* color: var(--text-color2); */
    margin-bottom: 12px;
    font-size: 14px;
    color: #fff;
}

.contact-form .inptFld {
    width: 100%;
    border: 0;
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 10px 0;
    font-size: 16px;
    color: #f6f6f6;
    border: none;
    border-bottom: 1px solid #e5d4d4;
    background-color: transparent !important;
    border-radius: 0 !important;
    font-family: var(--Syne-Regular);
}

.contact-form .row {
    margin-bottom: 8px;
}

.contact-form .inptFld:focus {
    outline-offset: -4px;
    outline: 0px solid #f93;
    color: #ffff;

}

.contact-form textarea {
    height: 110px;
    padding-top: 5px;
}

.contact-form .inptBtn {
    padding: 12px 20px;
    border: 0;
    background: var(--bg-color3);
    border-radius: 25px;
    font-size: 14px;
    color: #fff;
    margin: auto;
    letter-spacing: 1px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
    font-family: var(--Syne-Bold);
}


.country1 {
    width: 100%;
    position: relative;
    overflow: hidden;
}

.country1 img {
    width: 100%;
}

.country1 .country-icons {
    position: absolute;
    top: 0;
    left: 0%;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end;
    justify-content: center;
    padding-bottom: 20px;
    transition: 0.5s;
}

.country1 .country-icons:hover {
    background-color: rgba(0, 0, 0, 0.2);
}

.country1 .country-icons h3 {
    color: #fff;
    font-family: var(--Syne-Bold);
    font-size: 22px;
    text-shadow: 2px 4px 10px rgb(32 6 6 / 50%);
    transition: all 0.5s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

.country1 .country-icons h3:hover {
    animation: move 0.5s ease-in-out alternate;
}

@-webkit-keyframes move {

    100%,
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(10px);
    }

    40% {
        transform: translateX(-10px);
    }

    70% {
        transform: translateX(10px);
    }
}

@keyframes move {

    100%,
    0% {
        transform: translateX(0);
    }

    20% {
        transform: translateX(10px);
    }

    40% {
        transform: translateX(-10px);
    }

    70% {
        transform: translateX(10px);
    }
}

.footer {
    background-color: #444449;
    ;
    ;
    font-family: var(--Syne-Bold);
}


.row {
    display: flex;
    flex-wrap: wrap;
}

ul {
    list-style: none;
}

.footer-col {

    padding: 0 15px;
}

.footer-col h4 {
    font-size: 20px;
    color: #FFF;
    text-transform: capitalize;
    margin-bottom: 10px;
    font-weight: 500;
    position: relative;
    font-family: var(--Syne-Medium);
}

.footer-col ul {
    padding-left: 0;
    margin-bottom: 0;
}


.inptEmail {
    width: 100%;
    height: 50px;
    border: 0;
    margin: 0 0 10px;
    border-radius: 8px;
    padding: 0 20px;
    font-size: 16px;
    color: #000;
    border: none;
    border-bottom: 1px solid #f5eded;
}

.inptEmail:focus {
    outline-offset: -4px;
    outline: 0px solid #f93;
    color: white;
}


.footer-btn {
    width: 100%;
    height: 38px;
    border: 0;
    margin: 0 0 10px;
    border-radius: 49px;
    padding: 0 20px;
    font-size: 16px;
    background-color: #fff;
    color: #000;
    border: none;
    border-bottom: 1px solid #f5eded;
    transition: background-color 0.5s ease;
}

.footer-btn:hover {
    background-color: #000;
    color: #fff;
}



.footer-col ul li a {
    color: #DDD;
    display: block;
    font-size: 1rem;
    font-weight: 300;
    /* text-transform: capitalize; */
    text-decoration: none;
    transition: all 0.3s ease;
}

.footer-col input {
    border: none;
    /* Hide all borders */
    border-bottom: 1px solid black;
}

.footer-col ul li a:hover {
    color: #FFF;
    padding-left: 7px;
}

.footer-col .social-links {
    display: flex;
    align-items: center;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.footer-col .social-links a {
    color: #FFF;
    background-color: transparent;
    display: inline-block;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    transition: all 0.5s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    margin-right: 10px;
    border: 1px solid rgba(225, 225, 225, 0.8);
}

.footer-logo {
    position: relative;
}

.footer-logo img {
    margin-top: -30px;
    width: 230px;
}

.footer-col .social-links a:hover {
    color: #151515;
    background-color: #FFF;
}

.footer-col p {
    font-family: var(--Syne-Regular);
    color: #fff;
    font-size: 15px;
    padding: 20px 0;
    padding-right: 50px;
}

.footer-col ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.footer-col ul li a {
    font-family: var(--Syne-Regular);
    color: #fff;
    font-size: 15px;
    padding: 4px 0;
    text-decoration: none;
}


.footer-copyright {
    color: #fff;
    display: flex;
    justify-content: space-evenly;
    font-family: var(--Syne-Regular);
    padding-bottom: 50px;
    padding-top: 50px;
}

.footer-copyright p {
    margin-bottom: 0;
}

.footer-copyright a {
    text-decoration: none;
    color: #fff;
}

.copy-right-p p {
    text-align: left;
}

.privacy-policy p {
    text-align: center;
}

.website-link p {
    text-align: right;
}



.footer-form {
    position: relative;
}

.footer-form input {
    position: relative;
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #e5d4d4;
    border-radius: 0 !important;
    padding: 10px 0 0 !important;
    color: #fff;
    font-family: var(--Syne-Regular);
}

.footer-form input::placeholder {
    color: #fff;
    font-family: var(--Syne-Regular);
}

.footer-form button {
    display: block;
    background-color: #fff;
    color: var(--text-color2);
    position: relative;
    width: fit-content;
    font-size: 20px;
    text-decoration: none;
    font-family: var(--Syne-Medium);
    width: 100%;
    font-weight: 500;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    margin-top: 15px;
}

.footer-form button span {
    transform: scale(0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--text-color);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    transition-delay: 0.2s;
}

.footer-form button:hover span {
    transform: scale(1);
    opacity: 1;
}

.footer-form button::before {
    content: "";
    background: var(--bg-color3);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.footer-form button:hover {
    color: #fff;
}

.footer-form button:hover::before {
    width: 100%;
    color: var(--text-color2);
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.faq-section {
    margin: 80px 0;
}

.faq-section .header-title h3 {
    color: #fff;
}

.faq-section-b {
    /* background-image: url("../images/img-bg2.png"); */
    width: 100%;
    background-color: var(--bg-color3);
    margin: 0 0 100px;
    position: relative;
}

.faq-section-bo {
    background-color: #0000009e;
    padding-left: 100px;
    padding-right: 100px;
}

.faq-section-b .header-title {
    color: #fff !important;
    text-align: center;
    justify-content: center;
    flex-direction: column;
}

.faq-section-b .header-title h6 {
    color: #fff !important;
}

.faq-box .accordion-item {
    margin-bottom: 30px;
    background-color: transparent !important;
    border: none !important;
}

.faq-box .accordion-body {
    background-color: transparent;
    margin-top: 20px;
    border-radius: 10px;
    color: #f1eaea;
    letter-spacing: 1px;
    font-family: var(--Syne-Regular);
}

.faq-box .accordion-item:last-child {
    margin-bottom: 0;
}

.accordion-item:first-of-type {
    border-radius: 0 !important;
}

.accordion-item:first-of-type .accordion-button,
.accordion-item .accordion-button {
    border-radius: 10px !important;
}

.faq-box .accordion-button:focus {
    box-shadow: none;
}

.faq-box .accordion-button {
    color: var(--bg-colo2);
}

.faq-box .accordion-button:not(.collapsed) {
    background-color: var(--bg-color3) !important;
    /* border-color: var(--bg-color3) !important; */
    color: #fff;
    box-shadow: none;
}

.faq-box .accordion-button::after {
    background-image: none !important;
    content: '';
    font-size: 45px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--Syne-Regular);
    font-weight: 100;
    /* border: 1px solid var(--bg-colo2); */
    padding: 0;
    /* width: 25px;
    height: 25px; */
    color: var(--text-color2);
    margin-bottom: 3px;
    background-image: url('../images/plus.png') !important;

}

.faq-box .accordion-button:not(.collapsed)::after {
    background-image: none !important;
    content: '';
    color: #fff;
    /* border: 1px solid #fff; */
    background-image: url('../images/minus.png') !important;
}

.award_item {
    background-color: #1c1b17;
    padding: 20px;
    /* border-right: 1px solid #717171; */
}

.award_item img {
    width: 100%;
}

.achievement-section {
    margin-bottom: 0;
    background-color: #1c1b17;
}

.achievement-section .header-title {
    width: 65%;
    margin: auto;
    padding-bottom: 40px;
}

.achievement-section .header-title h3 {
    text-align: center;
    color: #fff;
}

.achievement-section .header-title p {
    text-align: center;
    color: #fff;
}

.hoverButton {
    position: relative;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    margin-top: 15px;
}

.hoverButton span {
    transform: scale(0);
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    color: var(--text-color);
    z-index: 11;
    display: flex;
    align-items: center;
    justify-content: center;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;
    transition-delay: 0.2s;
}

.hoverButton:hover span {
    transform: scale(1);
    opacity: 1;
}

.hoverButton::before {
    content: "";
    background: var(--text-color2);
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 0;
    height: 100%;
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50px;
}

.hoverButton:hover {
    color: #fff;
}

.hoverButton:hover::before {
    width: 100%;
    color: var(--text-color2);
    opacity: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-map-img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 50%;
    height: 80%;
    z-index: -1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mobile-map-img img {
    width: 75%;
}


.button_effect {
    position: relative;
    overflow: hidden;
    padding: 15px 20px;
}

.button_effect:before {
    content: '';
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 1s;
}

.button_effect:after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -100%;
    width: 100%;
    height: 2px;
    background-color: black;
    transition: all 1s;
}

.button_effect:hover:before {
    left: 0;
}

.button_effect:hover:after {
    right: 0;
}

.button_effect .line_border:before {
    content: '';
    position: absolute;
    top: -100%;
    right: 0%;
    width: 2px;
    height: 100%;
    background-color: black;
    transition: all 1s;
}

.button_effect:hover .line_border:before {
    top: 0;
}

.button_effect .line_border:after {
    content: '';
    position: absolute;
    bottom: -100%;
    left: 0%;
    width: 2px;
    height: 100%;
    background-color: black;
    transition: all 1s;
}

.button_effect:hover .line_border:after {
    bottom: 0;
}

.progress-circle-container {
    position: fixed;
    bottom: 90px;
    right: 20px;
    z-index: 9999;
    display: flex;
    align-items: center;
    justify-content: center;
}

.progress-circle {
    width: 60px;
    height: 60px;
}

.progress-circle-bar {
    fill: none;
    stroke: #ededed;
    stroke-width: 5;
    stroke-dasharray: 283;
    stroke-dashoffset: 0;
    transform-origin: center;
    transition: stroke-dashoffset 0.3s ease;
}

.progress-background {
    fill: transparent;
    stroke: #ededed;
    stroke-width: 5;
    stroke-dasharray: none;
}


.scroll-to-top {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: black;
    border-radius: 50%;
    cursor: pointer;
    transition: opacity 0.3s ease;
    z-index: 9998;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
}

.scroll-to-top svg {
    display: block;
    width: 20px;
    height: 20px;
    stroke: white;
    transition: all 0.1s ease;
}

.uparrow {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

}

.uparrow a {
    color: var(--bg-color1);
    background-color: #d3d3d4;
    display: inline-block;
    height: 45px;
    width: 45px;
    border-radius: 50%;
    text-align: center;
    line-height: 40px;
    transition: all 0.5s ease;
    /* position: fixed; */
    /* right: 20px; */
    /* bottom: 80px; */
    z-index: 11;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
}

.circular-chart {
    display: block;
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
    position: relative;
    z-index: 1;
    width: 60px;
    height: 60px;
}

.circle-bg {
    fill: none;
    stroke: #e6e6e6;
    stroke-width: 2;
}

.circle {
    fill: none;
    stroke: #000;
    stroke-width: 2;
    stroke-linecap: round;
    animation: progress 1s ease-out forwards;
}

@keyframes progress {
    0% {
        stroke-dasharray: 0 100;
    }
}


.uparrow a:hover {
    color: #151515;
    background-color: #FFF;
}

.call_to_action_on_mobile {
    display: none;
}

.inner-banner {
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
}

.inner-banner::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 80%;
    height: 1px;
    border-radius: 50px;
    background-color: var(--border-color1);
}

.breadcrumb .star {
    font-size: 13px;
    /* color: var(--bg-color3); */
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 10px;
}

.breadcrumb a,
.breadcrumb-item.active {
    text-decoration: none;
    color: #fff;
}

.breadcrumb-item+.breadcrumb-item::before {
    content: '*' !important;
    display: flex;
    align-items: center;
    justify-content: center;
    /* line-height: 20px; */
    padding-top: 4.5px;
    font-size: 30px;
    display: none !important;
}

.about-section-1 .right-section {
    padding-left: 0;
}

.about-section-1 .right-section .countup-section {
    position: relative;
    left: 0;
}

.about-section-1 .left-section {
    position: relative;
    left: 0;
}

.about-section-1 .bg_overly {
    position: absolute;
    top: 0;
    left: 0;
    background: url('../images/bg_42.png');
    background-size: contain;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-position: center center;
    background-size: cover;
    opacity: 0.1;

}

.about-section-1 .left-section .top-section-box {
    display: flex;
}

.about-section-1 .left-section .top-section-box .circle-box {
    border-radius: 50%;
    height: 290px;
    width: 260px;
    transition: all 0.5s ease 0s;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
    /* overflow: hidden; */
}

.about-section-1 .left-section .top-section-box .circle-box:hover {
    transform: translateY(-15px);
}

.about-section-1 .left-section .top-section-box .same_box img {
    width: 270px;
    height: 270px;

}

.top-section-box-one {
    align-items: end;
}

.about-section-1 .left-section .top-section-box .second {
    display: flex;
    align-items: end;
}

.about-section-1 .left-section .top-section-box .second img {
    margin-top: 0;
    margin-left: 30px;
    width: 220px;
    height: 220px;
    position: relative;
}

.about-section-1 .left-section .top-section-box .three {
    display: flex;
    align-items: start;
    justify-content: end;
}

.about-section-1 .left-section .top-section-box .three img {
    margin-top: 0;
    margin-right: 20px;
}

.about-section-1 .left-section .top-section-box .three img {
    width: 200px;
    height: 200px;
    position: relative;

}

.about-section-1 .left-section .top-section-box .second::after {
    position: absolute;
    content: '';
    left: 0;
    top: -30px;
    /* background-image: url('../images/logo_Asset-6-800x800.png'); */
    width: 70px;
    height: 70px;
    background-size: cover;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-duration: 14s;
    -moz-animation-duration: 14s;
    -webkit-animation-name: FloatingxUp;
    -moz-animation-name: FloatingxUp;
    animation: FloatingxUp 14s infinite ease-in-out;
    opacity: 0.4;
    display: none;

}

.about-section-1 .left-section .top-section-box .three::after {
    position: absolute;
    content: '';
    left: 0;
    bottom: -55px;
    /* background-image: url('../images/logo_Asset-6-800x800.png'); */
    width: 100px;
    height: 100px;
    background-size: cover;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation-timing-function: ease-in-out;
    -moz-animation-iteration-count: infinite;
    -moz-animation-timing-function: ease-in-out;
    -webkit-animation-duration: 14s;
    -moz-animation-duration: 14s;
    -webkit-animation-name: FloatingxUp;
    -moz-animation-name: FloatingxUp;
    animation: FloatingxUp 14s infinite ease-in-out;
    display: none;

}

.about-section-1 .left-section .top-section-box .circle-box img {
    /* width: 100%;
    height: 100%;
    object-fit: cover; */
    border-radius: 50%;
}

.top-section-box-two {
    margin-top: 20px;
    position: relative;
    align-items: flex-start;
}

.about-section-2 h3 {
    color: var(--bg-color3) !important;
    text-align: center;
}

.about-section-2 .brand-logo {
    padding-top: 30px;
}

.about-section-2 .brand-logo .brand_logo-slider .logo-box {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
}

.about-section-2 .brand-logo .brand_logo-slider .logo-box img {
    width: 90%;
    /* opacity: 0.5; */
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.about-section-2 .brand-logo .brand_logo-slider .logo-box:hover img {
    opacity: 1;
}

.header-center {
    text-align: center;
    margin-bottom: 60px;
}

.header-center h3 {
    color: var(--bg-color3);
}

.header-center h3 i {
    font-size: 16px;
}

.header-center p {
    width: 90%;
    text-align: center;
    margin: auto;
}

.about-section-3 {
    background-color: var(--bg-color4);
}

.about-section-3 .card-box {
    position: relative;
    background-color: #fff;
    padding: 40px 40px 40px 40px;
    border-radius: 10px;
}

.about-section-3 .card-box .icon-box {
    width: 60px;
    margin-bottom: 20px;
}

.about-section-3 .card-box .icon-box img {
    width: 100%;
}

.about-section-3 .card-box p {
    font-size: 16px;
}

.team-profile {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.team-profile .profile-box {
    position: relative;
    overflow: hidden;
    width: 250px;
    height: 250px;
    border-radius: 50%;

}

.team-profile .profile-box img {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.team-profile .profile-box .team-social-media {
    position: absolute;
    bottom: -100%;
    opacity: 0;
    -webkit-transition: all 0.5s ease 0s;
    -moz-transition: all 0.5s ease 0s;
    -ms-transition: all 0.5s ease 0s;
    -o-transition: all 0.5s ease 0s;
    transition: all 0.5s ease 0s;
}

.team-profile:hover .profile-box .team-social-media {
    opacity: 1;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    background-color: #00207b73;
}

.team-profile:hover .profile-box .team-social-media li {
    list-style: none;
}

.team-profile:hover .profile-box .team-social-media li a {
    padding: 0 10px;
    display: inline-block;
    color: #000;
    width: 30px;
    height: 30px;
    line-height: 32px;
    font-size: 12px;
    background-color: #fff;
    text-align: center;
    -webkit-transition: all .3s;
    transition: all .3s;
    border-radius: 50%;
    margin: 0 5px;
}

.team-profile:hover .profile-box .team-social-media li a:hover {
    background-color: rgba(0, 0, 0, .8);
    border-radius: 50%;
    color: #fff;
}


.team-profile:hover .profile-box .team-social-media li a:hover>i {
    -webkit-animation: iconTranslateY .4s forwards;
    animation: iconTranslateY .4s forwards
}

@-webkit-keyframes iconTranslateY {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }

    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    51% {
        opacity: 1
    }
}

@keyframes iconTranslateY {
    49% {
        -webkit-transform: translateY(100%);
        transform: translateY(100%)
    }

    50% {
        opacity: 0;
        -webkit-transform: translateY(-100%);
        transform: translateY(-100%)
    }

    51% {
        opacity: 1
    }
}

.team-profile h4 {
    text-align: center;
    padding-top: 20px;
    color: #000;
    font-family: var(--Syne-Bold);
    font-size: 18px;
    padding-bottom: 10px;
    margin-bottom: 0;
}

.team-profile h5 {
    text-align: center;
    color: var(--bg-color3);
    font-family: var(--Syne-Bold);
    font-size: 14px;
}

.team-section-1 .team-profile {
    margin-bottom: 40px;
}

.service_box {
    position: relative;
    margin-bottom: 30px;
}

.service_box .thumb-img {
    height: 180px;
    width: 100%;
    z-index: 1;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    border: 1px solid #00207b73;
}

/* .service_box .thumb-img::after {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: #00207b73;
    z-index: 2;
} */

.service_box .thumb-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.service-detail-box {
    padding: 20px 0 10px;
}

.service-detail-box p {
    color: var(--text-color3);
    font-family: var(--Syne-Regular);
    margin-bottom: 0;
    font-size: 16px;
}

.service-detail-box h4 {
    font-size: 18px;
    font-family: var(--Syne-Bold);
}

.service-detail-box a {
    text-decoration: none;
    font-size: 14px;
    text-transform: uppercase;
    color: var(--text-color1);
    margin-top: 20px;
    display: flex;
    align-items: center;
    font-family: var(--Syne-Medium);
}

.service-detail-box a i {
    margin-left: 10px;
    background-color: var(--bg-color3);
    color: #fff;
    border-radius: 50%;
    padding: 0;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 10px;
    line-height: 11px;
}

.service-detail-left-side {
    position: relative;
}

.service-detail-left-side p {
    color: #444449;
    font-size: 16px;
}

.three-images-container {
    position: relative;
    display: flex;
    margin-top: 30px;
    margin-bottom: 30px;
}

.three-images-container .first-img-box {
    width: 55%;
    padding-right: 10px;
}

.three-images-container .first-img-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.three-images-container .second-img-box {
    width: 45%;
    padding-left: 10px;
}

.three-images-container .second-img-box .child-box {
    width: 100%;
    height: 47.8%;

}

.three-images-container .second-img-box .child-box:first-child {
    margin-bottom: 20px;
}

.three-images-container .second-img-box .child-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.service-detail-left-side ul {
    padding-left: 0;
    margin-bottom: 0;

}

.service-detail-left-side ul li {
    font-size: 16px;
    color: var(--text-color3);
    padding: 5px 0;
}

.service-detail-left-side ul li i {
    color: var(--text-color1);
    margin-right: 10px;
}

.faq-section-inner {
    padding-top: 30px;
}

.faq-section-inner .accordion-button {
    background-color: #f7f6fb !important;
}

.faq-section-inner .faq-box .accordion-body {
    color: #000 !important;
}

.faq-section-inner .faq-box {
    margin-top: 40px;
}

.right-section-service {
    position: sticky;
    color: white;
    background-color: #444449;
    border-radius: 8px;
    padding: 40px;
    top: 100px;

}

.right-section-service p {
    color: var(--text-color3);
    font-size: 16px;
}

.right-section-service ul {
    padding-left: 10px;
    margin-bottom: 0;
}

.right-section-service ul li a {
    font-size: 16px;
    font-family: var(--Syne-Regular);
    display: flex;
    align-items: center;
    text-decoration: none;
    color: white;
}

.right-section-service ul li i {
    color: white;
    font-size: 6px;
    margin-right: 8px;

}

.right-section-service ul li {
    font-size: 16px;
    font-family: var(--Syne-Regular);
    display: flex;
    align-items: center;
    padding: 3px 0;
}

.case_studies {
    position: sticky;
    top: 100px;
    padding-left: 20px;
}

.case_studies p {
    color: var(--text-color3);
    font-size: 16px;
    font-family: var(--Syne-Regular);
}

.case_studies .address {
    padding-left: 0;
    margin-bottom: 0;

}

.case_studies .address li span {
    color: var(--bg-color3);
    font-size: 12px;
    margin-right: 8px;
}

.case_studies .address li {
    font-size: 16px;
    font-family: var(--Syne-Regular);
    display: flex;
    align-items: center;
    padding: 5px 0;
}

.social-media {
    position: relative;
    padding: 20px 0;
}

.social-media ul {
    padding-left: 0;
    margin-bottom: 30px;
    display: flex;
    align-items: center;
}

.social-media ul li a {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    background-color: #000;
    font-size: 12px;
    text-decoration: none;
    margin-right: 10px;
    border-radius: 50%;
}

.social-media ul li a i {
    margin-left: 3px;
}

.recent-post {
    position: relative;
}

.recent-post ul {
    padding-left: 0;
    margin-bottom: 0;
}

.recent-post ul li {
    display: flex;
    align-items: center;
    margin-bottom: 8px;
}

.recent-post ul li:last-child {
    margin-bottom: 0;
}

.recent-post ul li a {
    display: flex;
    align-items: center;
    text-decoration: none;
}

.recent-post ul li .thumb-box {
    width: 40%;
    height: 90px;
}

.recent-post ul li .thumb-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
}

.recent-post ul li .details-box {
    width: 60%;
    padding-left: 15px;
}

.recent-post ul li .details-box span {
    font-size: 14px;
    color: var(--text-color1);
}

.recent-post ul li .details-box p {
    font-size: 14px;
    font-family: var(--text-color2);
}

.single-details-page {
    position: relative;
}

.single-details-page .banner-part {
    position: relative;
}

.single-details-page .banner-part img {
    width: 100%;
}

.single-details-page .header-part {
    padding: 40px 0 20px;
}

.single-details-page .header-part ul {
    padding-left: 0;
    margin-bottom: 0;
}

.single-details-page .header-part ul li {
    color: var(--text-color1);
    font-size: 16px;
    text-transform: uppercase;
    font-family: var(--Syne-Medium);
}

.single-details-page .heading {
    padding-bottom: 20px;
}

.single-details-page p {
    color: var(--text-color3);
}

.mb_10px {
    margin-bottom: 25px;
}

.mt_10px {
    margin-top: 25px;
}

.middle-heading {
    position: relative;
    padding: 20px 40px;
}

.middle-heading::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 1px;
    height: 100%;
    background-color: var(--bg-colo2);
    display: none;
}

.middle-heading p {
    font-size: 18px;
    color: var(--text-color2);
    font-family: var(--Syne-Bold);
    border-left: 1px solid var(--bg-color3);
    padding-left: 15px;
    margin-bottom: 0;
    padding-top: 10px;
    padding-bottom: 10px;
}

.single-img-group {
    padding: 10px 0 35px;
}

.single-img-group .img-card-box {
    width: 100%;
}

.single-img-group .img-card-box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 10px;
}

.single-footer-section {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 10px;
}

.single-footer-section p {
    font-size: 14px;
    color: var(--text-color2);
    font-family: var(--Syne-Medium);
    text-transform: uppercase;
    margin-bottom: 0;
}

.single-footer-section p span {
    color: var(--text-color1);
}

.single-footer-section div {
    display: flex;
    align-items: center;
    color: var(--text-color2);
    font-size: 14px;
    font-family: var(--Syne-Medium);
}

.single-footer-section div ul {
    padding-left: 0;
    margin-bottom: 0;
    display: flex;
    align-items: center;
    margin-left: 10px;
}

.single-footer-section div ul li a {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background-color: var(--bg-colo2);
    color: #fff;
    font-size: 12px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-decoration: none;
    margin-right: 10px;
}

.testimonials_section_inner .card-box {
    margin: 20px;
}

.testimonials-section .card-box h5 {
    font-family: var(--Syne-SemiBold);
    font-size: 14px;
    text-transform: uppercase;
    color: var(--text-color1);
}

.testimonials-section .card-box .read_more {
    border: none;
    outline: none;
    background-color: transparent;
    color: var(--text-color1);
    font-family: var(--Syne-Medium);
    font-family: 16px;
    padding: 2px 0;
    position: relative;
}

.testimonials-section .card-box .read_more::after {
    position: absolute;
    content: '';
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: var(--bg-color3);
}

.testimonials-section .card-box .read_more:hover:after {
    animation: width 0.5s ease-in-out alternate;
}

@keyframes width {

    100%,
    0% {
        width: 100%;
    }

    50% {
        width: 20%;
    }

    70% {
        width: 60%;
    }
}

.testimonials-section .card-box {
    background-color: var(--bg-color4);
}

.testimonials-section .card-box .icon-box {
    background-color: var(--bg-color4);
    border-radius: 50%;
    overflow: hidden;
}

.home_brand_logo img {
    filter: grayscale(1);
    -webkit-transition: all 0.3s ease 0s;
    -moz-transition: all 0.3s ease 0s;
    -ms-transition: all 0.3s ease 0s;
    -o-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
}

.home_brand_logo:hover img {
    filter: grayscale(0);
}

.about_content p.p1 {
    font-size: 16px;
    line-height: 20px;
}

.location_card {
    width: 100%;
    height: 400px;
    position: relative;
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
}

.location_card img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.location_content {
    background: linear-gradient(180deg, #4738DE00 0%, var(--bg-color3) 100%);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    padding: 30px;
    display: flex;
    flex-direction: column;
    justify-content: end;
}

.location_content h4 {
    color: #fff;
    font-family: var(--Syne-Bold);
    font-size: 20px;
}

.location_content ul {
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}

.location_content ul li {
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    font-family: var(--Syne-Regular);
    letter-spacing: 1px;
    display: flex;
    align-items: start;
    padding: 4px 0;

}

.location_content ul li a {
    text-decoration: none;
    font-size: 16px;
    color: #fff;
    font-family: var(--Syne-Regular);
    letter-spacing: 1px;
    display: flex;
    align-items: center;
}

.location_content ul li p {
    margin-bottom: 0;
    font-size: 16px;
    color: #fff;
    font-family: var(--Syne-Regular);
    letter-spacing: 1px;
}

.location_content ul li i {
    font-size: 14px;
    margin-right: 8px;
    width: auto;
    margin-top: 6px;
}

.loader_full_page {
    position: fixed;
    z-index: 11111;
    width: 100%;
    left: 0;
    top: 0;
    height: 100vh;
    width: 100%;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader_full_page .loader_img {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.loader_full_page .loader_img img {
    width: 50%;
    position: relative;
    bottom: -15px;
}

.project_f .featured-section {
    padding-bottom: 0 !important;
}

@-webkit-keyframes FloatingxUp {
    0% {
        -webkit-transform: translate(30px)
    }

    65% {
        -webkit-transform: translate(0)
    }

    to {
        -webkit-transform: translate(30px)
    }
}

@keyframes FloatingxUp {
    0% {
        -webkit-transform: translate(30px)
    }

    65% {
        -webkit-transform: translate(0)
    }

    to {
        -webkit-transform: translate(30px)
    }
}

.card_box_gallery {
    position: relative;
    padding: 15px;
    cursor: pointer;
}

.card_box_gallery .gallery-thumb {
    width: 100%;
}

.card_box_gallery .gallery-thumb img {
    width: 100%;
}

.card_box_gallery h4 {
    color: var(--text-color1);
    margin-top: 20px;
}

.card_box_gallery a {
    color: var(--text-color1);

}

.cookie-section {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    border-radius: 0;
    background-color: #fff;
    z-index: 111;
    padding: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.4);
    transform: translateY(100%);
    opacity: 0;
    transition: 0.6s;
    display: flex;
    justify-content: space-between;
    display: none;
}

.cookie-section div {
    width: 90%;
}

.cookie-section.active {
    transform: translateY(0);
    opacity: 1;
}

.cookie-section h4 {
    color: var(--text-color1);
    font-family: var(--Syne-Bold);
    font-family: 18px;
    margin-bottom: 10px;
}

.cookie-section p {
    font-family: var(--Syne-Regular);
    font-size: 14px;
    color: #000;
}

.cookie-section button {
    padding: 5px 10px;
    background-color: var(--bg-color3);
    color: #ffff;
    border-radius: 50px;
    margin-top: 15px;
    border: none;
    outline: none;
    width: fit-content;
    font-size: 14px;
}

.news_link-box {
    text-decoration: none;
    color: #000;
}

@media(max-width:1299px) {
    .heading {
        font-size: 46px;
        line-height: 55px;
    }
}

@media(max-width:1199px) {
    .banner-section .banner-content h1 {
        font-size: 40px;
        line-height: 45px;
        margin: 8px 0 20px;
    }

    .heading {
        font-size: 40px;
        line-height: 45px;

    }

    .paragraph {
        font-size: 18px;
        line-height: 22px;
    }

    .banner-section .banner-content p {
        font-size: 18px;
        line-height: 22px;
        margin-bottom: 40px;
    }

    .mobile-menu {
        height: 100vh;
        overflow: hidden;
        overflow-y: scroll;
    }

    .menu-right-img .lamp_light_section {
        margin-right: 20px;
    }

    .mobile-menu-list {
        margin-right: -80px;
    }

    .bottom {
        padding-bottom: 80px;
    }

    .top {
        padding-top: 80px;
    }

    .mobile-menu-list {
        margin-right: 150px;
    }

    .team-profile .profile-box {
        width: 200px;
        height: 200px;
    }

    .about-section-1 .left-section .top-section-box .same_box img {
        width: 250px;
        height: 250px;
    }

    .about-section-1 .left-section .top-section-box .second img {
        margin-top: 0;
        margin-left: 0;
        width: 200px;
        height: 200px;
        position: relative;
    }

    .about-section-1 .left-section .top-section-box .three img {
        width: 180px;
        height: 180px;
        position: relative;
    }

    .about-section-1 .left-section .top-section-box .circle-box {
        border-radius: 50%;
        height: 250px;
        width: 260px;
        transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        /* overflow: hidden; */
    }
}



@media(max-width:1080px) {
    .banner-section .banner-content h1 {
        font-size: 35px;
        line-height: 40px;
        margin: 8px 0 20px;
    }

    .heading {
        font-size: 35px;
        line-height: 40px;
    }

    .service-child-section {
        padding: 60px 45px;
    }

    .marketing-slider .swiper-slide .card-box h4 {
        font-size: 18px;
    }

    .marketing-slider .swiper-slide .card-box p {
        font-size: 14px;
    }

    .contact-detail .call-to-action {
        width: 100%;
    }

    .padding-left-right {
        padding-left: 40px;
        padding-right: 40px;
    }

}

@media(max-width:991px) {
    .banner-section .banner-content h1 {
        font-size: 30px;
        line-height: 35px;
        margin: 8px 0 15px;
    }

    .banner-section .banner-content {
        width: 100%;
    }

    .banner-section {
        height: 100vh;
    }

    .bottom {
        padding-bottom: 50px;
    }

    .top {
        padding-top: 50px;
    }

    .marketing-section .right-section {
        margin-top: 40px;
        padding-right: 0;
    }

    .business-body .paragraph1 {
        width: 100%;
    }

    .business-body .card-box {
        padding-top: 40px;
    }

    .contact-sec {
        padding: 0;
    }

    .portfolio_section .swiper-button-next,
    .portfolio_section .swiper-button-prev {
        width: 40px !important;
        height: 40px !important;
        font-size: 20px;
    }

    .business-body .card-box .cards {
        margin-bottom: 15px;
    }

    .footer-fomr-section {
        padding-left: 0;
        margin-top: 50px;
    }

    .faq-two-section {
        margin-top: 30px;
    }

    .faq-section-b,
    .image-slider {
        margin-bottom: 50px;
    }


    .menu-right-img .lamp_light_section {
        width: 30%;
    }

    .mobile-menu-list li a {
        font-size: 24px;
    }

    .contact-detail .call-to-action {
        width: 50%;
    }

    .mobile-menu-list {
        margin-right: 120px;
    }


    .achievement-section .header-title {
        width: 80%;
        padding-bottom: 20px;
    }

    .colum-menu {
        padding-left: 50px;
    }

    .mobile-menu .contact-detail .call-to-action {
        width: 60% !important;
    }

    .mobile-menu .contact-detail .call-to-action a {
        font-size: 14px !important;
    }

    .about-section-1 .left-section .top-section-box {
        justify-content: center;
        align-items: center;
    }

    .about-section-1 .left-section {
        margin-top: 60px;
    }

    .order_1 {
        order: 1;
    }

    .order_2 {
        order: 2;
    }
}

@media(max-width:768px) {
    .heading br {
        display: none;
    }

    .banner-section .banner-content p {
        width: 100%;
    }

    .heading {
        font-size: 30px;
        line-height: 35px;
    }

    .countup-section .count_box h5 {
        font-size: 25px;
    }

    .countup-section .count_box p {
        font-size: 14px;
    }

    .country1 .country-icons h3 {
        font-size: 30px;
    }

    .copy-right-p p {
        text-align: center;
    }

    .privacy-policy p {
        text-align: center;
    }

    .website-link p {
        text-align: center;
    }

    .logo-slider {
        padding-right: 0;
    }

    .contact-detail .call-to-action {
        width: 80%;
    }

    .menu-right-img .lamp_light_section {
        width: 40%;
    }

    .colum-menu {
        display: none;
    }

    .col-md-6.menu-img {
        width: 100%;
    }

    .mobile-map-img {
        left: 40%;
        width: 70%;
    }

    .call_to_action_on_mobile {
        display: block;
        position: absolute;
        bottom: 30px;
        left: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
    }

    .call_to_action_on_mobile ul {
        padding-left: 0;
        margin-bottom: 0;
        display: flex;
    }

    .call_to_action_on_mobile a {
        display: flex;
        color: #fff;
        /* justify-content: center; */
        align-items: center;
        text-decoration: none;
        margin-right: 15px;
    }

    .top-call-to {
        margin-bottom: 20px;
    }

    .top-call-to a {
        margin-left: 0;
        font-size: 14px !important;
    }

}

.inner-banner {
    align-items: center;
    background-image: url('../images/about.jpg');
    background-position: 10%;
    background-size: cover;
    color: #fff;
    display: flex;
    flex-direction: column;
    height: 350px;
    justify-content: center;
    position: relative;
}

.inner-banner::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
}

.breadcrumb>li>Link {
    color: white;
}

.inner-banner>* {
    position: relative;
    z-index: 2;
}

@media(max-width:650px) {
    .menu-right-img .lamp_light_section {
        width: 50%;
    }

    .mobile-menu-list li a {
        font-size: 20px;
    }
}

@media(max-width:576px) {
    .padding-left-right {
        padding-left: 15px;
        padding-right: 15px;
    }

    .banner-section .banner-content h1 {
        font-size: 25px;
        line-height: 30px;
        margin: 4px 0 10px;
    }

    .sub-heading {
        font-size: 16px;
    }

    .banner-section .banner-content {
        padding-top: 40px;
    }

    .heading {
        font-size: 25px;
        line-height: 30px;
    }

    .paragraph {
        font-size: 16px;
        line-height: 22px;
    }

    .countup-section {
        position: relative;
        bottom: 0;
        margin-top: 50px;
        padding: 15px 10px;
        border-radius: 20px;
    }

    .marketing-section .right-section {
        padding-left: 0;
    }

    .featured-section .header-title {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }

    .featured-section .header-title a {
        justify-content: center;
    }

    .slider-para {
        margin: 20px 0 25px;
        text-align: center;
        font-weight: 500;
        width: 100%;
        font-size: 16px;
    }

    .portfolio-slider {
        padding-left: 0;
        padding-right: 0;
    }

    .featured-section .header-title {
        text-align: center;
        justify-content: center;
    }

    .business-body {
        padding: 40px 20px;
    }

    .business-body .card-box {
        padding-top: 25px;
    }

    .marketing-slider {
        z-index: 11;
        margin-top: 30px;
        padding-bottom: 65px !important;
    }

    .mb-40px {
        margin-bottom: 35px;
    }

    .portfolioSwiper .swiper-slide .card-body p {
        font-size: 14px;
    }

    .contact-detail .call-to-action a {
        width: 100%;
    }

    .contact-detail .call-to-action a {
        justify-content: center;
        text-align: center;
    }

    .section-title {
        position: relative;
        font-size: 26px;
        font-weight: 600;
        text-align: center;
        margin: 0 0 15px;
    }

    .contact-detail .call-to-action {

        padding: 16px 0 10px;
    }

    .contact-detail h2 {
        text-align: center;
    }

    .contact-form-footer {
        width: 100%;
        padding: 30px 15px;
        background-color: #f7f6fb;
        border-radius: 10px;

    }

    .contact-detail .call-to-action {
        width: 100%;
    }

    .contact-detail .social_media {
        text-align: center;
    }

    .contact-detail .social_media ul {
        justify-content: center;
    }

    .right_s {
        padding-bottom: 10px;
    }

    .service-child-section {
        padding: 60px 18px;
    }

    .portfolio_section {
        margin-bottom: 0;
    }

    .fontact-us-home {
        margin-bottom: 0;
    }

    .business {
        margin-bottom: 0;
    }

    .achievement-section {
        margin-bottom: 0;
    }

    .image-slider {
        margin-bottom: 0;
    }

    .mobile-menu-list {
        padding-left: 10px;
    }

    .mobile-menu {
        padding-top: 30px;
    }

    .mobile-menu-list li a {
        font-size: 20px;
        padding: 2px 0;
    }

    .menu-right-img .lamp_light_section {
        width: 50%;
    }

    .menu-right-img .lamp_light_section {
        margin-right: 0;
    }

    .mobile-menu-list {
        margin-right: 40px;
        padding-top: 60px;
    }

    .menu-right-img .lamp_light_section {
        width: 65%;
    }

    .menu-right-img {
        padding: 30px 0px 0 0;
    }

    .banner-section {
        height: 100vh;
    }

    .btn-class {
        padding: 8px 30px;
    }

    .contact-detail .social_media ul li:last-child a {
        margin-right: 0;
    }

    .country1 .country-icons h3 {
        font-size: 20px;
    }

    .achievement-section .header-title {
        width: 100%;
        padding-bottom: 20px;
    }

    .menu {
        width: 50px;
        height: 60px;
    }

    .menu-right-img .lamp_light_section {
        height: auto;
    }

    .banner-section .dot-img {
        left: 25px;
        top: 100px;
        width: 50px;
    }

    .menu-right-img .lamp_light_section img {

        top: 35%;
        transform: translateY(-60%);
        right: -40px;
        width: 100%;
    }

    .mobile-menu {
        height: 70vh;
    }

    .mobile-map-img {
        left: 0;
        width: 50%;
        top: 10%;
        transform: translateY(-10%);
    }

    .service_box .thumb-img {
        height: auto;
    }

    .right-section-service {
        position: relative;
        padding: 20px;
        top: 30px;
    }

    .team-section-1 .team-profile {
        margin-bottom: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .about-section-2 .brand-logo .brand_logo-slider .logo-box img {
        width: 100%;
    }

    .about-section-2 .brand-logo .brand_logo-slider .logo-box {
        padding: 10px;
    }

    .carrer-section-1 h4 {
        font-size: 20px;
        text-align: center;
        margin-bottom: 20px;
    }

    .carrer-section-1 h5 {
        font-size: 16px;
        text-align: center;
    }

    .about-section-1 .left-section .top-section-box .same_box img {
        width: 200px;
        height: 200px;
    }

    .about-section-1 .left-section .top-section-box .second img {
        margin-top: 0;
        margin-left: 10px;
        width: 150px;
        height: 150px;
        position: relative;
    }

    .about-section-1 .left-section .top-section-box .three img {
        width: 140px;
        height: 140px;
        position: relative;
    }

    .about-section-1 .left-section .top-section-box .circle-box {
        border-radius: 50%;
        height: 200px;
        width: 260px;
        transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        /* overflow: hidden; */
    }

    .about-section-1 .left-section .top-section-box .three img {
        margin-top: 0;
        margin-right: 10px;
    }

    .contact-detail h3 {
        text-align: center;
    }

    .call_to_action_on_mobile {
        display: block;
        position: absolute;
        top: 10px;
        left: 10px;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        padding-right: 40px;
    }

    .top-call-to a {
        margin-left: 0;
        font-size: 16px !important;
        padding: 6px 0;
    }

    .top-call-to a i {
        margin-right: 5px;
    }

    .mobile-map-img {
        left: -60px;
        width: 95%;
        top: 20%;
        transform: translateY(-10%);
    }

    .countup-section .count_box {
        width: 50%;
        padding: 10px 15px;
    }

    .countup-section {
        flex-wrap: wrap;
        left: 0;
        top: 0;
        margin-top: 0;
    }

    .inner-banner {
        padding: 15px;
    }

    .inner-banner h1 {
        text-align: center;
    }

    .breadcrumb {
        justify-content: center;
    }

    .footer-col {
        padding: 10px 15px;
    }

    .footer-col h4 {
        margin-bottom: 20px;
        font-size: 25px;
    }
}

@media(max-width:426px) {
    .about-section-1 .left-section .top-section-box .same_box img {
        width: 150px;
        height: 150px;
    }

    .about-section-1 .left-section .top-section-box .second img {
        margin-top: 0;
        margin-left: -7px;
        width: 110px;
        height: 110px;
        position: relative;
    }

    .about-section-1 .left-section .top-section-box .circle-box {
        border-radius: 50%;
        height: 115px;
        width: auto;
        transition: all 0.5s ease 0s;
        transition: all 0.5s ease 0s;
        /* overflow: hidden; */
    }

    .about-section-1 .left-section .top-section-box .three img {
        width: 90px;
        height: 90px;
        position: relative;
    }

    .about-section-1 .left-section .top-section-box .same_box img {
        width: 120px;
        height: 120px;
    }

    .about-section-1 .left-section .top-section-box .second img {
        margin-left: 4px;
    }

}

@media(max-width:375px) {
    .menu-right-img .lamp_light_section {
        margin-right: 5px;
    }

    .mobile-menu-list {
        margin-right: 80px;
    }

    .menu-right-img .lamp_light_section {
        width: 50%;
    }
}

@media(max-width:320px) {
    .banner-section .banner-content h1 {
        font-size: 20px;
        line-height: 28px;
        margin: 2px 0 10px;
    }

    .sub-heading {
        font-size: 14px;
    }

    .heading {
        font-size: 20px;
        line-height: 28px;
    }

    .service-child-section {
        padding: 45px 20px;
    }

    .explain-more a {
        font-size: 16px;
    }

    .mb-40px {
        margin-bottom: 35px;
    }

    .menu-right-img .lamp_light_section {
        width: 85%;
    }

    .menu-right-img .lamp_light_section {
        margin-right: -8px;
    }

    .mobile-menu-list {
        padding-top: 55px;
    }
}



/* Header */

* {
    margin: 0;
    padding: 0;
}

.pages {
    /* color: #316685; */
    text-align: center;
    /* font-size: calc(1.5rem + 2vw); */
    /* margin-top: 1%; */
}

.navbar {
    background-color: #fff;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 0;
    z-index: 20;
}

.nav-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1500px;
}

.main-container {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.3);
}

.nav-logo {
    color: #000;
    align-items: center;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    flex-grow: 1;

}

.nav-logo img {
    height: 69px;
    width: 144px;
}

.nav-menu {
    display: flex;
    list-style: none;
    text-align: center;
    margin-right: 2rem;
    margin-bottom: 0;
}

.nav-links {
    color: #000;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
    border-bottom: 3px solid transparent;
}

.fa-code {
    margin-left: 1rem;
}

.nav-item {
    line-height: 40px;
    margin-right: 1rem;
}

.nav-item:after {
    content: "";
    display: block;
    height: 1px;
    width: 0;
    background: transparent;
    transition: width 0.7s ease, background-color 0.5s ease;
}

/* .nav-item:hover:after {
    width: 100%;
    background: #444449;
} */

.nav-item.active {
    color: #444449;
    border: 1px solid #444449;
}

.nav-icon {
    display: none;
}

@media screen and (max-width: 960px) {

    .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        border-top: 1pxsolid #e4eff1;
        position: absolute;
        top: 80px;
        left: -110%;
        opacity: 1;
        transition: all 0.5s ease;
    }

    .nav-menu.active {
        background: #f3f8fb;
        left: 0px;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
    }

    .nav-item .active {
        color: #444449;
        border: none;
    }

    .nav-links {
        padding: 1.5rem;
        width: 100%;
        /* display: table; */
    }

    .nav-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
        color: #444449;
    }
}

.details-page {
    display: flex;
    justify-content: space-around;
}

/* Base styles */
.nav__submenu {
    display: none;
    position: absolute;
    background-color: #f3f8fb;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    margin-left: 40px;
    padding-left: 0px;
    text-align: left;
}

.nav-item:hover .nav__submenu {
    display: block;
}

.nav__submenu-item a {
    display: block;
    padding: 8px 16px;
    text-decoration: none;
    color: black;
}

.nav__submenu-item a:hover {
    /* background-color: #444449; */
    color: rgb(0, 0, 0);
}

/* Media query for mobile and tablet views */
@media (max-width: 768px) {
    .nav__submenu {
        display: block;
        position: static;
        box-shadow: none;
    }
}

@media (max-width: 576px) {
    .nav-logo img {
        height: 45px;
        width: 100px;
    }

    .nav__submenu {
        display: none;
        position: absolute;
        background-color: #f3f8fb;
        box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
        margin-left: 40px;
        padding-left: 0px;
        text-align: center;
    }
}


header {
    background-color: #2874f0;
    padding: 0 5%;
    box-shadow: 0 10px 20px rgb(0, 0, 0/5%), 0 6px 6px rgb(0, 0, 0/6%);
}

header nav {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
}

.logo {
    flex: 2;
    display: flex;
    align-items: center;
}

.logo a {
    text-decoration: none;
    font-size: 26px;
    color: #fff;
    text-transform: uppercase;
    font-weight: 800;
}

.bartoggle,
#menubrop {
    display: none;
}

.NavMenu {
    flex: 10;
    list-style: none;
    position: relative;
    display: flex;
    justify-content: end;
}

.NavMenu li {
    display: inline-block;
}

.NavMenu li input {
    display: none;
}

.NavMenu li a {
    display: block;
    padding: 20px 14px;
    font-size: 17px;
    text-decoration: none;
    text-transform: uppercase;
    color: #fff;
    position: relative;
}

.NavMenu li a label {
    cursor: pointer;
    appearance: none;
    display: block;
    position: relative;
}

.NavMenu li a label::after {
    content: "+";
    position: absolute;
    right: 0;
    top: 0;
    font-size: 20px;
}

.NavMenu>li>a label::after {
    right: -15px;
    top: -3px;
}

.NavMenu li ul {
    position: absolute;
    display: flex;
    flex-direction: column;
    background-color: #fff;
    min-width: 200px;
    border-bottom: 2px solid #2874f0;
    top: 100%;
    box-shadow: 0 3px 5px rgb(0 0 0/20%);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
    transform: translateY(10px);
    -webkit-transform: translateY(10px);
    -moz-transform: translateY(10px);
    -ms-transform: translateY(10px);
    -o-transform: translateY(10px);
    visibility: hidden;
    opacity: 0;
}

.NavMenu li ul li {
    position: relative;
}

.NavMenu li ul li a {
    color: #2874f0;
    padding: 8px 10px;
    display: block;
    border-left: 2px solid transparent;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -ms-transition: all 0.5s;
    -o-transition: all 0.5s;
}

.NavMenu li ul li ul {
    position: absolute;
    left: 100%;
    top: 0;
}

@media(min-width:992px) {
    .NavMenu li ul li a:hover {
        border-left: 2px solid #2874f0;
    }

    .NavMenu li:hover>ul,
    .NavMenu li ul li:hover>ul {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        -webkit-transform: translateY(0);
        -moz-transform: translateY(0);
        -ms-transform: translateY(0);
        -o-transform: translateY(0);
    }
}

@media(max-width:991.98px) {
    header {
        padding: 6px 5%;
    }

    .logo {
        flex: 6;
    }

    .bartoggle {
        display: flex;
        justify-content: center;
        font-size: 30px;
        align-items: center;
        background-color: #fff;
        padding: 0 10px;
        cursor: pointer;
    }

    .NavMenu {
        width: 500px;
        flex: 12;
        position: fixed;
        flex-direction: column;
        background-color: #2874f0;
        left: 0;
        top: 40px;
        height: 100vh;
        z-index: -1;
        padding: 15px 0 50px 0;
        justify-content: start;
        overflow-y: scroll;
        transition: all 0.5s;
        -webkit-transition: all 0.5s;
        -moz-transition: all 0.5s;
        -ms-transition: all 0.5s;
        -o-transition: all 0.5s;
        transform: translateX(-100%);
        -webkit-transform: translateX(-100%);
        -moz-transform: translateX(-100%);
        -ms-transform: translateX(-100%);
        -o-transform: translateX(-100%);
    }

    .NavMenu li ul,
    .NavMenu li ul li ul {
        position: initial;
        left: 0;
        visibility: visible;
        opacity: 1;
        top: 0;
        display: none;
    }

    .NavMenu li a {
        padding: 8px 15px;
        border-bottom: 1px solid #fff;
    }

    .NavMenu li ul li ul {
        background: #2874f0;
        position: inherit;
        margin-top: -10px;
    }

    .NavMenu li ul li ul li a {
        font-size: 14px;
        color: #fff;
        font-weight: 400;
        text-transform: initial;
        padding: 7px 15px 7px 30px;
    }

    .NavMenu li a label::after {
        right: 10px;
    }

    .NavMenu li input:checked+ul,
    .NavMenu li ul li input:checked+ul {
        display: flex;
        flex-direction: column;
        margin-bottom: 15px;
    }

    input:checked+.NavMenu {
        transform: translateX(0);
        -webkit-transform: translateX(0);
        -moz-transform: translateX(0);
        -ms-transform: translateX(0);
        -o-transform: translateX(0);
    }

}

@media(max-width:768px) {
    .NavMenu {
        width: 100%;
    }
}

.card-icon {
    font-size: 2rem;
    margin-bottom: 15px;
}

.about-section-2 .card {
    /* width: 320px; */
    background-color: white !important;
    margin-bottom: 30px;
    border: none;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: 'transform 0.2s, box-shadow 0.2s';
}

.about-section-2 .card:hover {
    background-color: #f1f2fd !important;
}